<template>
  <section>
    <validation-observer
      #default="{ handleSubmit, invalid, pristine }"
      ref="refFormObserver"
    >
      <b-card
        header-tag="header"
        border-variant="info"
        header-bg-variant="light-info"
        header-class="py-1"
        class="border my-1"
      >
        <template #header>
          <h6 class="m-0">
            {{ $t('notification.sendNotification') }}
          </h6>
        </template>

        <!-- Form Input -->
        <b-card-body class="p-0 pt-1">
          <!-- SECTION Form -->
          <b-form>
            <!-- SECTION Fields -->
            <b-row>
              <!-- ANCHOR SUBJECT -->
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  :name="$t('notification.subject')"
                  rules="required"
                >
                  <b-form-group label-for="subject">
                    <template #label>
                      {{ $t('notification.subject') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <b-form-input
                      id="subject"
                      v-model="notificationDataToAdd.subject"
                      :state="getValidationState(validationContext) === false ? false : null"
                      :placeholder="$t('notification.subject')"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR CONTENT -->
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  :name="$t('notification.content')"
                  rules="required"
                >
                  <b-form-group label-for="content">
                    <template #label>
                      {{ $t('notification.content') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <b-form-textarea
                      id="content"
                      v-model="notificationDataToAdd.content"
                      :state="getValidationState(validationContext) === false ? false : null"
                      :placeholder="$t('notification.content')"
                      max-rows="3"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR RECIPIENT REF TYPE -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('notification.recipientRefType')"
                  rules="required"
                >
                  <b-form-group
                    label-for="recipientRefType"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <template #label>
                      {{ $t('notification.recipientRefType') }}
                    </template>
                    <v-select
                      v-model="notificationDataToAdd.recipientRefType"
                      :options="recipientRefTypeOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="recipientRefType"
                      :placeholder="$t('notification.recipientRefType')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR CHANNEL -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('notification.channel')"
                  rules="required"
                >
                  <b-form-group
                    label-for="type"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <template #label>
                      {{ $t('notification.channel') }}
                    </template>
                    <v-select
                      v-model="notificationDataToAdd.channel"
                      :options="channelOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="channel"
                      :placeholder="$t('notification.channel')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR SEND TYPE -->
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('notification.sendType')"
                  rules="required"
                >
                  <b-form-group
                    label-for="sendType"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <template #label>
                      {{ $t('notification.sendType') }}
                    </template>
                    <v-select
                      v-model="notificationDataToAdd.sendType"
                      :options="sendTypeOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="sendType"
                      :placeholder="$t('notification.sendType')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR RESCHEDULE PERIOD IN SECOND -->
              <b-col
                v-if="notificationDataToAdd.sendType === 'SCHEDULE'"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('notification.reschedulePeriodInSecond')"
                  rules="required"
                >
                  <b-form-group
                    label-for="reschedulePeriodInSecond"
                    :state="getValidationState(validationContext) === false ? false : null"
                  >
                    <template #label>
                      {{ $t('notification.reschedulePeriodInSecond') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <v-select
                      v-model="notificationDataToAdd.reschedulePeriodInSecond"
                      :options="reschedulePeriodInSecondTypeOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="reschedulePeriodInSecond"
                      :placeholder="$t('notification.reschedulePeriodInSecond')"
                    >
                      <template #option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $te(data.label) ? $t(data.label) : data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR SEND TIME -->
              <b-col
                v-if="notificationDataToAdd.sendType === 'SCHEDULE'"
                cols="12"
                md="6"
              >
                <b-form-group label-for="sendTime">
                  <template #label>
                    {{ $t('notification.sendTime') }}
                    <span class="text-danger">(*)</span>
                  </template>
                  <flat-pickr
                    v-model="notificationDataToAdd.sendTime"
                    class="form-control"
                    :config="{
                      minDate: 'today',
                      allowInput: true,
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      altFormat: 'Y-m-d H:i',
                      altInput: true,
                      locale: $i18n.locale === 'vi' ? Vietnamese : null,
                      disableMobile: true,
                    }"
                  />
                </b-form-group>
              </b-col>

              <!-- ANCHOR DEAD LINE -->
              <b-col
                v-if="notificationDataToAdd.sendType === 'SCHEDULE'"
                cols="12"
                md="6"
              >
                <b-form-group label-for="deadline">
                  <template #label>
                    {{ $t('notification.deadline') }}
                    <span class="text-danger">(*)</span>
                  </template>
                  <flat-pickr
                    v-model="notificationDataToAdd.deadline"
                    class="form-control"
                    :config="{
                      minDate: 'today',
                      allowInput: true,
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      altFormat: 'Y-m-d H:i',
                      altInput: true,
                      locale: $i18n.locale === 'vi' ? Vietnamese : null,
                      disableMobile: true,
                    }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- !SECTION  -->
          </b-form>
          <!-- !SECTION -->
        </b-card-body>
      </b-card>
      <!-- SECTION Action Buttons -->
      <div class="d-flex mt-1 justify-content-center">
        <b-button
          variant="secondary"
          @click="backHandle"
        >
          {{ $t('back') }}
        </b-button>

        <b-button
          variant="danger"
          class="mx-1"
          :disabled="pristine"
          @click="clearHandle"
        >
          {{ $t('employee.clear') }}
        </b-button>

        <b-button
          variant="info"
          :disabled="invalid"
          @click="handleSubmit(nextHandle)"
        >
          {{ $t('next') }}
        </b-button>
      </div>
      <!-- !SECTION -->
    </validation-observer>
  </section>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton, BRow, BCol, BCard,
  BCardBody,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import moment from 'moment'

import {
  recipientRefTypeOptions, sendTypeOptions, reschedulePeriodInSecondTypeOptions, channelOptions,
} from '@/constants/selectOptions'
import { apiAgencies } from '@/api'

import formValidation from '@core/comp-functions/forms/form-validation'
import { convertISODateTime } from '@core/utils/filter'

import useToast from '@useToast'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormTextarea,
    flatPickr,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const { toastSuccess, toastError } = useToast()

    const blankNotificationData = {
      subject: '',
      content: '',
      recipientRefType: 'EMPLOYEE',
      channel: 'PUSH_NOTI',
      sendType: 'INSTANT',
    }

    const notificationDataToAdd = ref(JSON.parse(JSON.stringify(blankNotificationData)))
    const resetNotificationData = () => {
      notificationDataToAdd.value = JSON.parse(JSON.stringify(blankNotificationData))
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetNotificationData)

    watch(notificationDataToAdd, value => {
      if (value.sendType === 'SCHEDULE') {
        notificationDataToAdd.value.isReschedule = true
        notificationDataToAdd.value.isActive = true
        notificationDataToAdd.value.reschedulePeriodInSecond = 3600
        if (!notificationDataToAdd.value.sendTime || !notificationDataToAdd.value.deadline) {
          notificationDataToAdd.value.sendTime = new Date()
          notificationDataToAdd.value.deadline = new Date().setDate(new Date().getDate() + 1)
        }
      } else {
        notificationDataToAdd.value.isReschedule = false
        notificationDataToAdd.value.isActive = false
      }
    }, { immediate: true, deep: true })

    function nextHandle() {
      if (moment(notificationDataToAdd.value.sendTime).isAfter(notificationDataToAdd.value.deadline)) {
        toastError({
          title: 'notification.error',
          content: 'notification.sendTimeMustBeforeDeadline',
        })
        return
      }
      this.$bvModal.show('modal-api-loading')
      apiAgencies.sendNotiAll({
        ...notificationDataToAdd.value,
        sendTime: convertISODateTime(notificationDataToAdd.value.sendTime).ISOdatetime,
        deadline: convertISODateTime(notificationDataToAdd.value.deadline).ISOdatetime,
      })
        .then(() => {
          toastSuccess({
            title: 'notification.success',
            content: 'notification.sendNotificationSuccess',
          })
          resetForm()
        })
        .catch(() => {
          toastError({
            title: 'notification.error',
            content: 'notification.sendNotificationError',
          })
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    function backHandle() {
      this.$router.go(-1)
    }

    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }

    return {
      notificationDataToAdd,
      refFormObserver,
      getValidationState,

      Vietnamese,

      // select options
      recipientRefTypeOptions,
      sendTypeOptions,
      reschedulePeriodInSecondTypeOptions,
      channelOptions,

      // handle
      nextHandle,
      backHandle,
      clearHandle,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
